import store from '@public/js/store';

const handleErrorResponse = (err) => {
    // account for scenarios where the try catch calling this
    // catches an error unrelated to the API call.
    const status = err.response?.status;

    if (status == 405) {
        // 405 - the account is locked
        store.commit('profile/lockAccount');
    }
    else {
        // lets the catch in the try catch do its job
        throw err;
    }
};


export default handleErrorResponse;
