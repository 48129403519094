import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "Terms4xBonusing-modal",
  class: "modal Terms4xBonusing-modal",
  role: "dialog",
  "aria-labelledby": "modal-title"
}
const _hoisted_2 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", {
        innerHTML: $setup.t('Terms4xBonusingModal.body_copy', { tncURL: '/#/terms' })
      }, null, 8, _hoisted_2),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.closeModal()))
      }, _toDisplayString($setup.t('buttons:close')), 1),
      _createElementVNode("a", {
        href: "#",
        class: "close-btn",
        title: "Close modal",
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("i", {
          "aria-hidden": "true",
          class: "fa-solid fa-xmark"
        }, null, -1)
      ]))
    ])
  ]))
}