
    import { mapActions } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import MenuBar from '@components/MenuBar.vue';
    import PromoLinks from '@components/PromoLinks.vue';

    export default {
        components: {
            MenuBar,
            PromoLinks,
        },

        setup () {
            const { t } = useI18next(['global']);
            return { t };
        },

        methods: {
            ...mapActions({
                logOut: 'profile/logOut',
            }),
        },
    };
