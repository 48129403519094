import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@public/img/receipt.svg'
import _imports_1 from '@public/img/cup.svg'


const _hoisted_1 = {
  id: "file-upload-complete",
  class: "modal",
  role: "dialog",
  "aria-labelledby": "modal-title"
}
const _hoisted_2 = { id: "modal-title" }
const _hoisted_3 = { class: "upload-complete-graphics" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString($setup.t('headline_copy')), 1),
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "icon receipt" }, [
          _createElementVNode("img", {
            alt: "",
            src: _imports_0,
            role: "presentation"
          })
        ], -1)),
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (n) => {
          return _createElementVNode("div", {
            key: n,
            class: "dots",
            role: "presentation"
          })
        }), 64)),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "icon cup" }, [
          _createElementVNode("img", {
            alt: "",
            src: _imports_1,
            role: "presentation"
          })
        ], -1))
      ]),
      _createElementVNode("p", null, _toDisplayString($setup.t('body_copy')), 1),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _toDisplayString($setup.t('buttons:got_it')), 1),
      _createElementVNode("a", {
        href: "#",
        class: "close-btn",
        title: "Close modal",
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("i", {
          "aria-hidden": "true",
          class: "fa-solid fa-xmark"
        }, null, -1)
      ]))
    ])
  ]))
}