import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"
import _imports_0 from '@public/img/header.png'
import _imports_1 from '@public/img/landing_products/landing_harvest_hunt_image.png'


const _hoisted_1 = {
  id: "app",
  ref: "app"
}
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { "data-e2e": "global-header" }
const _hoisted_4 = {
  id: "global-header",
  tabindex: "-1"
}
const _hoisted_5 = { class: "left-group" }
const _hoisted_6 = { class: "body-copy" }
const _hoisted_7 = { class: "get-started" }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "right-group" }
const _hoisted_11 = {
  key: 0,
  class: "landing-fallpromo2024"
}
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { role: "main" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PromoLinks = _resolveComponent("PromoLinks")
  const _component_MenuBar = _resolveComponent("MenuBar")
  const _component_TheFooter = _resolveComponent("TheFooter")
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", null, [
      _createVNode(_component_MenuBar, null, {
        default: _withCtx(() => [
          _createVNode(_component_PromoLinks, { "aria-label": "nav_links" })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString($setup.t('global:title')), 1),
        _createElementVNode("div", {
          class: _normalizeClass(["landing-header", $options.checkFeatureFlag('fallPromo2024') ? 'landing-header-lockup-harvest-hunt' : 'landing-header-lockup-2024'])
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _cache[0] || (_cache[0] = _createElementVNode("img", {
                class: "header-img",
                src: _imports_0,
                alt: "Dunkin' At Home Extras"
              }, null, -1)),
              _createElementVNode("p", _hoisted_6, [
                _createElementVNode("strong", null, _toDisplayString($setup.t('headline_copy')), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("a", {
                  id: "register",
                  href: `oauth/authenticate?target=signup${_ctx.$store.state.app.affiliateId ? `&affilateId=${_ctx.$store.state.app.affiliateId}` : ''}`,
                  class: "button"
                }, _toDisplayString($setup.t('join')), 9, _hoisted_8),
                _createElementVNode("a", {
                  id: "log-in",
                  href: `oauth/authenticate?target=login${_ctx.$store.state.app.affiliateId ? `&affilateId=${_ctx.$store.state.app.affiliateId}` : ''}`,
                  class: "button"
                }, _toDisplayString($setup.t('login')), 9, _hoisted_9)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            ($options.checkFeatureFlag('fallPromo2024'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _withDirectives(_createElementVNode("h2", null, null, 512), [
                    [_directive_t, 'fallPromo2024.headline_copy']
                  ]),
                  _createElementVNode("p", {
                    innerHTML: $setup.t('fallPromo2024.body_copy', { dunkinfunUrl: _ctx.app.dunkinfunUrl })
                  }, null, 8, _hoisted_12),
                  _cache[1] || (_cache[1] = _createElementVNode("img", {
                    alt: "",
                    class: "landing-harvest-hunt-image",
                    height: "326",
                    src: _imports_1,
                    width: "603"
                  }, null, -1)),
                  _createElementVNode("p", {
                    class: "landing-fallpromo2024-disclaimer",
                    innerHTML: $setup.t('fallPromo2024.disclaimer', { fall24tb2URL: _ctx.app.fall24tb2Url })
                  }, null, 8, _hoisted_13)
                ]))
              : _createCommentVNode("", true)
          ])
        ], 2)
      ]),
      _createElementVNode("main", _hoisted_14, [
        _renderSlot(_ctx.$slots, "default", { class: "content" })
      ]),
      _createVNode(_component_TheFooter, {
        ref: "footer",
        hidelinks: "footer"
      }, null, 512)
    ])
  ], 512))
}