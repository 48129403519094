import axios from 'axios';
import api from './api';
import store from './store/index';
import { checkFeatureFlag } from './feature-flags';

const instance = axios.create();
// Deleting the instance default headers is required to prevent
// the request from failing due to access control policies on S3.
delete instance.defaults.headers.common['X-HW-Profile-Token'];
delete instance.defaults.headers.common['X-Auth0-Access-Token'];

async function getPhotoUploaderKey () {
    try {
        const { data } = await axios.post(`${api.base}/uploader/key`);
        return data;
    }
    catch (err) {
        console.error(err);
        throw err;
    }
}

export const submitPhoto = async (file) => {
    try {
        const { uploadHost, uploadPath } = await getPhotoUploaderKey();
        const formData = new FormData();

        formData.append('upload', file, file.name);

        // Limit receipt uploads immediately to eliminiate the possibility of uploading another receipt while we wait for the first one to be verified
        store.commit('profile/updateProfile', { receipt_limited: true });

        const response = await instance.post(`${uploadHost}${uploadPath}`, formData);

        axios.post(`${api.base}/receipts`, { receipt_url: response.data.outputs.orig.url })
        .then(() => {
            // if (checkFeatureFlag('notifications')) {
            store.commit('notifications/pollNotifications');
            // if the user doesn't have an accepted receipt yet, update the profile when this receipt is accepted
            if (!store.state.first_receipt_accepted) store.commit('profile/updateProfile', { first_receipt_accepted: true });
            // }
            return true;
        })
        // Lets the user upload another receipt when the uploaded receipt is rejected
        .catch(() => store.commit('profile/updateProfile', { receipt_limited: false }));

        return response;
    }
    catch (err) {
        console.error(err);
        throw err;
    }
};
