import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/rewards_catalog_card.png'


const _hoisted_1 = { class: "gift-card-reward-lockup" }
const _hoisted_2 = { class: "reward-value-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      src: _imports_0,
      alt: "Image of a Dunkin' Gift Card"
    }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString($setup.t(`reward_values.${$props.id}`)), 1)
    ])
  ]))
}