import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  id: "file-preview",
  class: "modal",
  role: "dialog",
  "aria-labelledby": "modal-title"
}
const _hoisted_2 = { id: "modal-title" }
const _hoisted_3 = {
  key: 0,
  "aria-hidden": "true",
  class: "fa-solid fa-spinner"
}
const _hoisted_4 = {
  ref: "previewImage",
  alt: "Preview of uploaded image"
}
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 0,
  "aria-hidden": "true",
  class: "fa-solid fa-spinner"
}
const _hoisted_7 = { class: "try-again" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[3] || (_cache[3] = _withModifiers($event => ($options.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString($setup.t('headline_copy')), 1),
      _createElementVNode("p", null, _toDisplayString($setup.t('body_copy')), 1),
      ($data.loadingImage)
        ? (_openBlock(), _createElementBlock("i", _hoisted_3))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("embed", _hoisted_4, null, 512), [
        [_vShow, !$data.loadingImage]
      ]),
      _createElementVNode("button", {
        disabled: $data.submitting,
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($options.submit()), ["prevent"]))
      }, [
        _createTextVNode(_toDisplayString($setup.t('buttons:looks_good')) + " ", 1),
        ($data.submitting)
          ? (_openBlock(), _createElementBlock("i", _hoisted_6))
          : _createCommentVNode("", true)
      ], 8, _hoisted_5),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("a", {
          href: "",
          onClick: _cache[1] || (_cache[1] = _withModifiers($event => ($options.closeModal({ tryAgain: true })), ["prevent"]))
        }, _toDisplayString($setup.t('try_again')), 1)
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-btn",
        title: "Close modal",
        onClick: _cache[2] || (_cache[2] = _withModifiers($event => ($options.closeModal()), ["prevent"]))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("i", {
          "aria-hidden": "true",
          class: "fa-solid fa-xmark"
        }, null, -1)
      ]))
    ])
  ]))
}