
    import { useI18next } from '@composables/i18next';
    import { checkFeatureFlag } from '../feature-flags';
    import BaseModal from './BaseModal';

    export default {
        name: 'FileUploadCompleteModalHH',

        extends: BaseModal,

        setup () {
            const { t } = useI18next(['file_upload_complete']);
            return { t };
        },

        data () {
            return {
                modalName: 'receipt-upload-complete',
            };
        },

        methods: {
            checkFeatureFlag,
            play_hh () {
                window.location.href = '/sso_redirect?target=dunkin_fall24';
                this.closeModal();
            },
        },
    };
