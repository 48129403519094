import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "social-share" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "social-share__icons" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["data-url", "title", "aria-label"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["data-url", "data-msg", "title", "aria-label"]
const _hoisted_9 = { key: 2 }
const _hoisted_10 = ["href", "title", "aria-label"]
const _hoisted_11 = {
  key: 0,
  class: "disclaimer recapdisc"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_track = _resolveDirective("track")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", {
      innerHTML: $setup.t('headline_copy')
    }, null, 8, _hoisted_2),
    _createElementVNode("p", {
      innerHTML: $setup.t('body_copy')
    }, null, 8, _hoisted_3),
    _createElementVNode("ul", _hoisted_4, [
      ($data.referralCodes.facebook)
        ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
            _createElementVNode("a", {
              class: "facebook_share",
              href: "#facebook_share",
              "data-url": `${_ctx.app.siteURL}?rcode=${$data.referralCodes.facebook}`,
              "data-e2e": "facebook-share-link",
              title: $setup.t('share:fb.link_title'),
              "aria-label": $setup.t('share:fb.link_title')
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("i", {
                "aria-hidden": "true",
                class: "fa-brands fa-facebook"
              }, null, -1)
            ]), 8, _hoisted_6)
          ]))
        : _createCommentVNode("", true),
      ($data.referralCodes.twitter)
        ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
            _createElementVNode("a", {
              class: "twitter_share",
              href: "#twitter_share",
              "data-url": `${_ctx.app.siteURL}?rcode=${$data.referralCodes.twitter}`,
              "data-e2e": "twitter-share-link",
              "data-msg": $setup.t('share:twitter.description'),
              title: $setup.t('share:twitter.link_title'),
              "aria-label": $setup.t('share:twitter.link_title'),
              onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($options.twitterSelection()), ["prevent"]))
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("i", {
                "aria-hidden": "true",
                class: "fa-brands fa-x-twitter"
              }, null, -1)
            ]), 8, _hoisted_8)
          ]))
        : _createCommentVNode("", true),
      ($data.referralCodes.email)
        ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
            _withDirectives((_openBlock(), _createElementBlock("a", {
              class: "mailto",
              href: $options.mailTo,
              target: "_blank",
              "data-e2e": "mailto",
              title: $setup.t('share:email_link_title'),
              "aria-label": $setup.t('share:email_link_title')
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("i", {
                "aria-hidden": "true",
                class: "fa-solid fa-envelope"
              }, null, -1)
            ]), 8, _hoisted_10)), [
              [_directive_track, ['click', { category: 'Email', label: 'email_share', value: 1 }]]
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    ($props.showDisclaimer)
      ? (_openBlock(), _createElementBlock("p", _hoisted_11, _cache[4] || (_cache[4] = [
          _createTextVNode(" This site is protected by reCAPTCHA and the Google "),
          _createElementVNode("a", {
            href: "https://policies.google.com/privacy",
            target: "_blank"
          }, "Privacy Policy", -1),
          _createTextVNode(" and "),
          _createElementVNode("a", {
            href: "https://policies.google.com/terms",
            target: "_blank"
          }, "Terms of Service", -1),
          _createTextVNode(" apply. ")
        ])))
      : _createCommentVNode("", true)
  ]))
}