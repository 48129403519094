
    import { useI18next } from '@composables/i18next';
    import { mapActions, mapState } from 'vuex';
    import gsap from 'gsap';
    import { Carousel as VueSlickCarousel, Slide } from 'vue3-carousel';
    import 'vue3-carousel/dist/carousel.css';
    import BaseModal from './BaseModal';

    const AVATARS_CONFIG = {"_public":true,"avatars":["donut","bacon","creamer","to-go-hot-drink","croissant","muffin","bagged-coffee","iced-coffee","hot-coffee","refresher"]};

    export default {
        name: 'AvatarSelectionModal',

        components: {
            Slide,
            VueSlickCarousel,
        },

        extends: BaseModal,

        props: {
            onboarding: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        setup () {
            const { t } = useI18next(['modal_content']);
            return { t };
        },

        data () {
            return {
                avatars: AVATARS_CONFIG.avatars,
                loading: true,
                selectedAvatarPosition: 0,
                baseSliderWidth: null,
                slickSettings: {
                    'arrows': false,
                    'centerMode': true,
                    'centerPadding': '10px',
                    'dots': false,
                    'variableWidth': true,
                    itemsToShow: 2.5,
                    wrapAround: true,
                },
            };
        },

        computed: {
            ...mapState(['profile']),
        },

        async mounted () {
            gsap.to(this.$refs.avatarSelection, {
                duration: 0.5,
                opacity: 1,
                ease: 'circ.out',
            });

            // Avoids FOUC (flash of unstyled content)
            this.$nextTick(() => {
                this.loading = false;
            });

            if (this.profile.avatar) this.selectedAvatarPosition = this.avatars.indexOf(this.profile.avatar);
            document.scrollingElement.classList.add('hide-scrollbars');
            while (!this.$refs.carousel) {
                await new Promise(resolve => setTimeout(resolve, 5));
            }
            this.scaleSlider();
            window.addEventListener('resize', this.scaleSlider);
        },

        beforeUnmount () {
            document.scrollingElement.classList.remove('hide-scrollbars');
            window.removeEventListener('resize', this.scaleSlider);
        },

        methods: {
            ...mapActions({
                'updateProfile': 'profile/updateProfile',
                'getProfile': 'profile/getProfile',
            }),
            skip () {
                this.closeModal(this.avatars[this.selectedAvatarPosition]);
            },
            async submitAvatarSelection () {
                const avatar = this.avatars[this.selectedAvatarPosition];
                try {
                    await this.updateProfile({
                        avatar,
                    });

                    this.getProfile();
                    this.closeModal(avatar);
                }
                catch (err) {
                    console.error(err);
                }
            },

            scaleSlider () {
                const { carouselContainer } = this.$refs;
                const slickList = carouselContainer.querySelector('.carousel__track');
                this.baseSliderWidth = this.baseSliderWidth || slickList.getBoundingClientRect().width;
            },
        },
    };
