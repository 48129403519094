
    import { mapActions } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import { BaseSelect } from '@components/form';
    import { RenderlessValidator as Validator } from 'rocketship-validator';
    import { birthDate as validatorGuards } from '../../../lib/validation/client-validators';
    import BaseModal from './BaseModal';

    const MONTHS = {"_public":true,"months":{"1":31,"2":29,"3":31,"4":30,"5":31,"6":30,"7":31,"8":31,"9":30,"10":31,"11":30,"12":31}}.months;

    export default {
        name: 'BirthdateModal',

        components: {
            Validator,
            BaseSelect,
        },

        extends: BaseModal,

        props: {
            onboarding: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        setup () {
            const { t } = useI18next(['profile', 'modal_content']);
            return { t };
        },

        data () {
            return {
                birthdate: {
                    month: '',
                    day: '',
                },
                validatorGuards,
            };
        },

        computed: {
            monthOptions () {
                return Object.keys(MONTHS).map(month => ({
                    label: this.t(`global:months.${month}`),
                    value: month,
                }));
            },
            dayOptions () {
                const options = [];
                for (let i = 1; i <= MONTHS[this.birthdate.month]; i++) {
                    options.push({
                        label: i,
                        value: i,
                    });
                }
                return options;
            },
        },

        methods: {
            ...mapActions({
                'updateProfile': 'profile/updateProfile',
                'getProfile': 'profile/getProfile',
            }),
            async submitBirthdate () {
                const { validator } = this.$refs;

                await validator.validate();


                if (validator.isValid) {
                    try {
                        // ID's if there's a different way we want to handle this, please have at it :)
                        await this.updateProfile({ birthdate: this.birthdate.month + '-' + this.birthdate.day });
                        await this.getProfile();
                        this.closeModal();
                    }
                    catch (err) {
                        console.error(err);
                    }
                }
            },
        },
    };
