import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal reward-redemption-complete-modal",
  role: "dialog",
  "aria-labelledby": "modal-title"
}
const _hoisted_2 = { id: "modal-title" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "disclaimer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString($setup.t('complete.headline_copy')), 1),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "complete-icon-container" }, [
        _createElementVNode("i", {
          "aria-hidden": "true",
          class: "fa-solid fa-check"
        })
      ], -1)),
      _createElementVNode("p", {
        innerHTML: $setup.t('complete.body_copy', { cost: $setup.t(`reward_values.${$props.reward.reward_id}`) })
      }, null, 8, _hoisted_3),
      _createElementVNode("p", null, _toDisplayString($setup.t('complete.share_copy')), 1),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = (...args) => ($options.sweepstakeConfirmationModal && $options.sweepstakeConfirmationModal(...args)))
      }, _toDisplayString($setup.t('buttons:continue')), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString($setup.t('disclaimer')), 1),
      _createElementVNode("a", {
        href: "#",
        class: "close-btn",
        title: "Close modal",
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("i", {
          "aria-hidden": "true",
          class: "fa-solid fa-xmark"
        }, null, -1)
      ]))
    ])
  ]))
}