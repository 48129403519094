
import store from '../store/index';
import { BlankLayout, DefaultLayout, HubLayout, ProfileLayout, LandingLayout } from '../layouts';

// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    // IntroPage = async () => import(
    //     /* webpackChunkName: 'profile' */
    //     '../views/IntroPage.vue'
    // ),
    LandingPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LandingPage.vue'
    ),
    // LoginPage = async () => import(
    //     /* webpackChunkName: 'profile' */
    //     '../views/LoginPage.vue'
    // ),
    // RegisterPage = async () => import(
    //     /* webpackChunkName: 'profile' */
    //     '../views/RegisterPage.vue'
    // ),
    OnboardingPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/OnboardingPage.vue'
    ),
    HubPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/HubPage.vue'
    ),
    ProfilePage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/ProfilePage.vue'
    ),
    TransactionHistoryPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/TransactionHistoryPage.vue'
    ),
    IneligiblePage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/IneligiblePage.vue'
    ),
    PausedAccountPage = async () => import(
        /* webpackChunkName: 'issue' */
        '../views/PausedAccountPage.vue'
    ),
    AccountLockedPage = async () => import(
        /* webpackChunkName: 'issue' */
        '../views/AccountLockedPage.vue'
    );

export default [
    { path: '/', redirect: (to) => {
        // Used to send server-side errors to the '/error' page.
        if (store.state.app.defaultRoute) {
            return store.state.app.defaultRoute;
        }
        // Default routes for logged-in users.
        else if (
            store.getters['profile/loggedIn'] &&
            !store.getters['profile/isSessionExpired']
        ) {
            return '/onboarding';
        }
        // Default route for a user we know nothing about.
        return '/landing';
    } },
    {
        path: '/landing',
        component: LandingPage,
        meta: {
            public: true,
            layout: LandingLayout,
        },
    },
    {
        name: 'login',
        path: '/login',
        redirect: (to) => '/hub',
        // component: LoginPage,
        // props: true,
        // meta: {
        //     public: true,
        //     layout: DefaultLayout,
        // },
    },
    {
        path: '/register',
        redirect: (to) => '/hub',
        // component: RegisterPage,
        // meta: {
        //     public: true,
        //     layout: DefaultLayout,
        // },
    },
    {
        path: '/onboarding',
        component: OnboardingPage,
        beforeEnter: async (to, from, next) => {
            // Setting this logic here, instead of in the redirect method on the '/' path, prevents users from accessing the onboarding page if the do not have the onboarding cookie
            const onboardingCookie = await store.getters['profile/getOnboardingCookie'];
            if (!onboardingCookie) next('/hub');
            else next();
        },
        meta: {
            layout: BlankLayout,
        },
    },
    {
        path: '/hub',
        component: HubPage,
        meta: {
            layout: HubLayout,
        },
    },
    {
        path: '/profile',
        component: ProfilePage,
        meta: {
            layout: ProfileLayout,
        },
    },
    {
        path: '/transaction_history',
        component: TransactionHistoryPage,
        meta: {
            layout: ProfileLayout,
        },
    },
    {
        path: '/ineligible',
        component: IneligiblePage,
        meta: {
            public: true,
            layout: DefaultLayout,
        },
    },
    {
        path: '/paused_account',
        component: PausedAccountPage,
        meta: {
            public: true,
            layout: DefaultLayout,
        },
    },
    {
        path: '/locked_account',
        component: AccountLockedPage,
        meta: {
            public: true,
            layout: DefaultLayout,
        },
    },
    {
        path: '/logout',
        redirect: () => {
            store.dispatch('profile/logOut');
            return '/landing';
        },
    },
];
