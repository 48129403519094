
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';
    import openModal, { CouponDisclaimerModal } from '.';

    export default {
        name: 'CouponModal',

        extends: BaseModal,

        props: {
            couponId: {
                type: Number,
                required: true,
            },
            couponKey: { type: String, required: true },
        },

        setup () {
            const { t } = useI18next(['coupons']);
            return { t };
        },

        data () {
            return {
                modalName: 'coupon',
            };
        },

        methods: {
            openTermsModal () {
                openModal(CouponDisclaimerModal, { couponId: this.couponId, couponKey: this.couponKey });
                this.closeModal();
            },
        },
    };
