
import axios from 'axios';
import { reactive } from 'vue';
import Cookies from 'js-cookie';
// We use this, rather than the full `jsonwebtoken` package,
// b/c we don't need and can't do any verification client-side
// anyway, and we save a bunch of huge encryption dependencies
// by skipping all that.
import { jwtDecode } from 'jwt-decode';
import config from 'rocketship-config';
import handleErrorResponse from '@public/js/api-error';
import api from '../../api';
import { setRecaptchaV3Challenge } from '../../recaptcha';
import { checkFeatureFlag } from '../../feature-flags';


const SESSION_PERSISTENCE_ENABLED = true;

const sessionStorageKey = 'hw-auth';
const regCookieSuffix = ':registeredUser';
const newUserCookieSuffix = ':newUser';
const onboardingCookieSuffix = ':onboarding';
const watchedVideoCookieSuffix = ':watchedVideo';
const auth0StorageKey = 'auth0-token';

const getDefaultState = () => reactive({
    token: getLocalStorageToken(),
    auth0Token: getLocalStorageAuth0Token(),
    newRegistrant: false,
    accountLocked: false,
});

const cleanProfile = (profile) => {
    if ('g-recaptcha-response' in profile) {
        // Remove from returned state
        delete profile['g-recaptcha-response'];
    }
    // Remove empty token to prevent overwriting a valid one.
    if ('token' in profile && !profile.token) {
        delete profile.token;
    }

    return profile;
};

const state = getDefaultState();

const browserTime = makeObservableBrowserTime();

updateAxiosToken(state.token);
updateAxiosAuth0Token(state.auth0Token);

const getters = {
    loggedIn: (state) => 'id' in state && state.id && state.id !== 'me',
    tokenExpirationTime: (state) => {
        if (state.token) {
            const { exp } = jwtDecode(state.token);
            // UTC timestamp in seconds.
            return exp;
        }
        return;
    },
    isSessionExpired: (state, getters, rootState) => {
        if (!state.token) return;

        const
            currentTime = browserTime.now / 1000,
            { tokenExpirationTime } = getters;

        return tokenExpirationTime <= currentTime;
    },
    returning: (state, getters, rootState) => Cookies.get(rootState.app.name + regCookieSuffix),
    newUser: (state, getters, rootState) => Cookies.get(rootState.app.name + newUserCookieSuffix),
    getOnboardingCookie: async (state, getters, rootState) => {
        const isOnboarding = await Cookies.get(rootState.app.name + onboardingCookieSuffix);
        return isOnboarding;
    },
    watchedVideo: (state, getters, rootState) => Cookies.get(rootState.app.name + watchedVideoCookieSuffix),
};

const mutations = {
    resetProfile (state) {
        console.log('resetProfile');
        // Remove everything already in state.
        for (const key in state) {
            delete state[key];
        }

        // Re-set any default keys.
        const defaultState = getDefaultState();
        for (const key in defaultState) {
            state[key] = defaultState[key];
        }
    },

    // data can be an array (to prevent having to commit multiple mutations in
    // a row), or a flat object
    updateProfile (state, data) {
        if (!Array.isArray(data)) {
            data = [data];
        }

        console.log('updateProfile');

        for (const values of data) {
            for (const key in values) {
                state[key] = values[key];

                if (key === 'token') {
                    const { token } = state;

                    if (token) {
                        setLocalStorageToken(token);
                    }

                    updateAxiosToken(token);
                }
            }
        }
    },

    deletePasswordsFromStore (state) {
        // Removes password data from the vuex store.
        // We don't need to store users' plain text passwords after they've been registered or logged in.
        const passwordFields = ['password', 'confirm_password', 'plainTextPassword'];
        passwordFields.forEach((key) => {
            delete state[key];
        });
    },

    lockAccount (state) {
        state.accountLocked = true;
    },
};

const actions = {
    async loadSession ({ state, dispatch, getters }) {
        if (state.token) {
            dispatch('setRegisteredUserCookie');

            // Skip API calls we can already tell won't succeed.
            if (getters.isSessionExpired) {
                console.warn('expired token, skipped loading session');
                return;
            }

            try {
                await dispatch('getProfile');

                if (state.newRegistrant) {
                    dispatch('setOnboardingCookie');
                }
                else {
                    dispatch('removeOnboardingCookie');
                }
            }
            catch (err) {
                console.error('error loading session', err);
                // We no longer do anything else about errors here, from expired
                // tokens or otherwise. This will let the router decide what to
                // do, e.g. if the route is `public: true`, they'll be able to
                // stay there. This used to kick users to `/`, which was too
                // aggressive for some use cases.
            }
        }
    },

    getProfile ({ dispatch }) {
        return dispatch('makeCall', {
            type: 'get',
            endpoint: 'profiles/me',
        });
    },

    async logIn ({ dispatch }, { tempProfile }) {

        // standard login
        const response = await dispatch('makeCall', {
            endpoint: 'profiles/login',
            tempProfile,
        });

        return response;
    },

    logOut ({ dispatch, commit }) {
        removeLocalStorageToken();
        removeLocalStorageAuth0Token();
        dispatch('removeNewUserCookie');
        commit('resetProfile');

        window.location.href = '/oauth/logout';
    },

    async register ({ dispatch }, { tempProfile }) {
        const response = await dispatch('makeCall', {
            endpoint: 'profiles',
            tempProfile,
        });

        dispatch('setNewUserCookie');

        // if the opt-in modal can live on the overlay
        // and the user is in the oauth flow
        // we'll need to update this to redirect to
        // the overlay (simlar to oauth login)
        return response;
    },

    async makeCall ({ state, dispatch, commit }, {
        type = 'post',
        endpoint,
        tempProfile = {},
    }) {
        commit('resetProfile');

        try {
            commit('updateProfile', tempProfile);
            const response = await axios[type](`${api.base}/${endpoint}`, state);
            const profile = cleanProfile(response.data.result.profile);
            commit('updateProfile', profile);
            commit('deletePasswordsFromStore');

            if (profile.token) {
                dispatch('setRegisteredUserCookie');
            }

            return response;
        }
        catch (err) {

            console.error(
                `error making ${endpoint} call`,
                err.message,
                err,
            );

            handleErrorResponse(err);

            throw err;
        }
    },

    async updateProfile ({ state, commit }, data) {
        try {
            // persist the fields if there's errors.
            commit('updateProfile', data);
            const response = await axios.post(`${api.base}/profiles/${state.id}`, data);
            const profile = cleanProfile(response.data.result.profile);
            commit('updateProfile', profile);
        }
        catch (err) {
            if (err.response?.status === 400) {
                const { data } = err.response;

                if (data?.message && data.message === 'invalid_address') {
                    return err.response;
                }
            }

            handleErrorResponse(err);

            console.error(err);
        }
    },

    async checkIfProfileExists ({ commit }, data) {
        commit('updateProfile', data);
        await axios.post(`${api.base}/profiles/exists`, data);
    },

    async recordEvent ({ state }, event_type) {
        try {
            return await axios.post(`${api.base}/profiles/record`, { event_type });
        }
        catch (err) {
            console.error(err);
            handleErrorResponse(err);
            throw err;
        }
    },

    async redeemReward ({ commit, state }, reward_id) {
        try {
            const recaptchaData = {};
            await setRecaptchaV3Challenge({ data: recaptchaData, action: 'redeem' });
            const { data: { data } } = await axios.post(`${api.base}/redeem`, { reward_id, ...recaptchaData });
            commit('updateProfile', { balance: state.balance - data.points });
            return data;
        }
        catch (err) {
            console.error(err);
            handleErrorResponse(err);
            throw err;
        }
    },

    async forgotPassword ({ state }, email) {
        try {
            const response = await axios.post(`${api.base}/profiles/password/forgot`, { email });
        }
        catch (err) {
            console.error(err);
            handleErrorResponse(err);
        }
    },

    async communicationsOptOut ({ state, getters, commit }, email) {
        try {
            const response = await axios.post(`${api.base}/profiles/optout`, { email });
            if (getters.loggedIn && email === state.email) commit('updateProfile', { primary_opt_in: false });
            return response;
        }
        catch (err) {
            console.error(err);
            handleErrorResponse(err);
            throw err;
        }
    },

    async resetPassword ({ state }, data) {
        try {
            await axios.post(`${api.base}/profiles/password/reset`, { data });
        }
        catch (err) {
            handleErrorResponse(err);
        }
    },


    setRegisteredUserCookie ({ rootState }) {
        Cookies.set(rootState.app.name + regCookieSuffix, 'yes');
    },

    setNewUserCookie ({ rootState }) {
        Cookies.set(rootState.app.name + newUserCookieSuffix, 'yes');
    },

    removeNewUserCookie ({ rootState }) {
        Cookies.remove(rootState.app.name + newUserCookieSuffix);
    },

    setOnboardingCookie ({ rootState }) {
        Cookies.set(rootState.app.name + onboardingCookieSuffix, 'yes');
    },

    removeOnboardingCookie ({ rootState }) {
        Cookies.remove(rootState.app.name + onboardingCookieSuffix);
    },

    setWatchedVideoCookie ({ rootState }) {
        Cookies.set(rootState.app.name + watchedVideoCookieSuffix, 'yes', { expires: 365 });
    },
};

// Send custom auth header with every AJAX request.
function updateAxiosToken (token) {
    if (token) {
        // Not using Authorization header due to review's Basic auth.
        axios.defaults.headers.common['X-HW-Profile-Token'] = token;
    }
    else {
        delete axios.defaults.headers.common['X-HW-Profile-Token'];
    }
}

function updateAxiosAuth0Token (token) {
    if (token) {
        axios.defaults.headers.common['X-Auth0-Access-Token'] = token;
    }
    else {
        delete axios.defaults.headers.common['X-Auth0-Access-Token'];
    }
}

function getLocalStorageToken () {
    if (!SESSION_PERSISTENCE_ENABLED) return;

    try { return window.localStorage.getItem(sessionStorageKey); }
    catch (err) { console.error('localStorage error', err); }
}

function getLocalStorageAuth0Token () {
    try { return window.localStorage.getItem(auth0StorageKey); }
    catch (err) { console.error('localStorage error', err); }
}

function setLocalStorageToken (token) {
    if (!SESSION_PERSISTENCE_ENABLED) return;

    try { window.localStorage.setItem(sessionStorageKey, token); }
    catch (err) { console.error('localStorage error', err); }
}

function setLocalStorageAuth0Token (token) {
    try { window.localStorage.setItem(auth0StorageKey, token); }
    catch (err) { console.error('localStorage error', err); }
}

function removeLocalStorageToken () {
    try { window.localStorage.removeItem(sessionStorageKey); }
    catch (err) { console.error('localStorage error', err); }
}

function removeLocalStorageAuth0Token () {
    try { window.localStorage.removeItem(auth0StorageKey); }
    catch (err) { console.error('localStorage error', err); }
}

// As opposed to `app/now`, which is server time at initial page load.
function makeObservableBrowserTime () {
    // Tick the clock once every second.
    const TICK_INTERVAL = 1000;

    const observableTime = reactive({
        now: Date.now(),
        // In case somebody wants to cancel this.
        intervalId: null,
    });

    observableTime.intervalId = setInterval(() => {
        observableTime.now = Date.now();
    }, TICK_INTERVAL);

    return observableTime;
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
