
    import { useI18next } from '@composables/i18next';
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { CookieGate } from '../vendor/cookie-gate/index.js';
    import openModal, { SessionExpirationModal } from './modals';
    import logClientError from './client-error';

    export default {
        name: 'App',

        components: {
            CookieGate,
        },

        computed: {
            ...mapState([
                'app',
                'profile',
                'ui',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
                isSessionExpired: 'profile/isSessionExpired',
                isModalOpen: 'ui/isModalOpen',
            }),
        },

        watch: {
            isSessionExpired (value) {
                if (value) {
                    this.openSessionExpirationModal();
                }
            },
            isModalOpen () {
                if (this.isModalOpen) {
                    document.body.classList.add('modal-open');
                }
                else {
                    document.body.classList.remove('modal-open');
                }
            },
            '$store.state.profile.accountLocked' (isAccountLocked) {
                if (isAccountLocked) {
                    this.$router.push('/paused_account');
                }
            },
        },

        setup () {
            const { t } = useI18next(['global', 'cookie_gate']);
            return { t };
        },

        mounted () {
            // Focus the h1 element to force screen readers back to the top after a navigation.
            // This will ensure screen readers will read the page again.
            // https://a11y-guidelines.orange.com/en/articles/single-page-app/#notify-user-of-page-changes
            this.$router.afterEach(() => {
                this.$nextTick(() => document.getElementById('global-header').focus());
            });

            if (this.$store.state.profile.accountLocked) {
                if (!this.$route.meta.public) {
                    this.$router.push('/paused_account');
                }
            }
        },

        // If a fatal error occurs rendering any of our descendent components, try
        // to navigate to the error page to preserve some UX.
        // https://vuejs.org/v2/api/#errorCaptured
        errorCaptured (err) {
            this.$router.push('/error');
            // Forward fatal Vue component errors, i.e. those that caused us to display
            // the error page here, to './client-error' and its server-side logging.
            logClientError(err, { fatal: true });
        },

        methods: {
            ...mapActions({
                logOut: 'profile/logOut',
            }),

            async openSessionExpirationModal () {
                await openModal(SessionExpirationModal);

                // After the modal is closed.
                this.logOut();
                this.$router.replace('/');
            },
        },
    };
