let hasInitialized = false;

function initializePixelIfPossible (id = '492443823024217') {
    if (!hasInitialized) {
        hasInitialized = true;
        const metaScript = document.createElement('script');
        metaScript.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${id}');
        `;
        document.body.appendChild(metaScript);
        const metaNoScript = document.createElement('noscript');
        const metaPixel = document.createElement('img');
        metaPixel.src = `https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`;
        metaPixel.width = 1;
        metaPixel.height = 1;
        metaPixel.style.display = 'none';
        metaNoScript.appendChild(metaPixel);
        document.body.appendChild(metaNoScript);
    }
}

function mediaPixelPageView () {
    if (!hasInitialized) {
        initializePixelIfPossible();
    }
    window.fbq('track', 'PageView');
}

function renderMediaPixel (action) {
    if (!hasInitialized) {
        initializePixelIfPossible();
    }
    window.fbq('track', action);
}

export { renderMediaPixel, mediaPixelPageView };
