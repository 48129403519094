
    import { useI18next } from '@composables/i18next';
    import OutgoingAffiliate from '@components/OutgoingAffiliate.vue';
    import openModal, { ExitToPromoModal } from '@public/js/modals';
    import { checkFeatureFlag } from '../feature-flags';
    import BaseModal from './BaseModal';

    export default {
        name: 'SweepstakesEntryEarnedModal',

        components: {
            OutgoingAffiliate,
        },

        extends: BaseModal,

        setup () {
            const { t } = useI18next(['modal_content']);
            return { t };
        },

        data () {
            return {
                modalName: 'SweepstakesEntryEarnedModal',
                activePromo: '',
            };
        },

        beforeMount () {
            this.activePromo = this.getActivePromotion();
        },

        methods: {
            checkFeatureFlag,
            // todo: can this be removed?
            // i'm fairly certain this can be removed, but i can't tell the best refactor for this yet
            // next activePromotion implementation, please try to get the apiKey out of the config for this value
            // if that doesn't make sense, please talk to Shanleigh about it, and it may take some memory jogging lol
            // the goal is to make everything 100% dynamic and not rely on hardcoded checkFeatureFlag calls like this
            getActivePromotion () {
                let activePromo;

                if (checkFeatureFlag('promotionWinter23')) {
                    activePromo = 'dunkin_cartcatch';
                }

                return activePromo;
            },

            async openExitModal () {
                this.closeModal();
                await openModal(ExitToPromoModal, {
                    apiKey: this.getActivePromotion(),
                });
            },
        },
    };
