
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';

    export default {
        name: 'CouponDisclaimerModal',

        extends: BaseModal,

        props: {
            couponKey: {
                type: String,
                required: true,
            },
        },

        setup () {
            const { t } = useI18next(['coupons']);
            return { t };
        },
    };
