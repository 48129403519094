
    import { useI18next } from '@composables/i18next';
    import { Carousel as VueSlickCarousel, Slide } from 'vue3-carousel';
    import { checkFeatureFlag } from '../feature-flags';
    import { openPopup } from '../popup';
    import 'vue3-carousel/dist/carousel.css';
    import BaseModal from './BaseModal';

    export default {
        name: 'PhotoUploadTipsModal',

        components: {
            Slide,
            VueSlickCarousel,
        },

        extends: BaseModal,

        props: {
            isUploadOnboarding: {
                required: false,
                type: Boolean,
                default: false,
            },
        },

        setup () {
            const { t } = useI18next(['upload_tips', 'buttons', 'intro', 'receipt_upload_tips']);
            return { t };
        },

        data () {
            return {
                currentSlideIndex: 0,
                loading: true,
                slides: 4,
                slickSettings: {
                    accessibility: true,
                    adaptiveHeight: false,
                    arrows: false,
                    dots: true,
                    focusOnSelect: true,
                    infinite: false,
                    slidesToScroll: 1,
                    slideToShow: 1,
                },
            };
        },

        computed: {
            btnCopy () {
                if (this.currentSlideIndex === this.slides - 1) {
                    if (this.isUploadOnboarding) {
                        return this.t('select_an_image');
                    }
                    else {
                        return this.t('continue');
                    }
                }

                return this.t('next');
            },
        },

        watch: {
            currentSlide () {
                this.checkForLastSlide();
            },
        },

        mounted () {
            // Avoids FOUC (flash of unstyled content)
            this.$nextTick(() => {
                this.loading = false;
            });
        },

        methods: {
            goToSlide (indexNum) {
                this.currentSlideIndex = indexNum;
            },
            checkFeatureFlag,

            checkForLastSlide () {
                this.refreshFocusableElements();

                if (this.currentSlideIndex === this.slides - 1) {
                    this.focusOnFirstElement();
                }
            },

            nextSlide () {
                if (this.currentSlideIndex < this.slides - 1) {
                    this.$refs.carousel.next();
                }
                else {
                    this.closeModal({ upload: true });
                }
            },

            openPopup,

            skipTips () {
                this.currentSlideIndex = this.slides - 1;
            },
        },
    };
