
    import { useI18next } from '@composables/i18next';
    import { mapGetters } from 'vuex';
    import { Carousel as VueSlickCarousel, Slide } from 'vue3-carousel';
    import 'vue3-carousel/dist/carousel.css';
    import BaseModal from './BaseModal';

    export default {
        name: 'HowItWorksModal',

        components: {
            Slide,
            VueSlickCarousel,
        },

        extends: BaseModal,

        props: {
            onboarding: {
                default: false,
                required: true,
                type: Boolean,
            },
        },

        setup () {
            const { t } = useI18next(['how_it_works']);
            return { t };
        },

        data () {
            return {
                currentSlide: 0,
                slides: ['welcome', 'earn', 'redeem', 'extra'],
                loading: true,
                slickSettings: {
                    arrows: false,
                    adaptiveHeight: true,
                    dots: true,
                    infinite: false,
                },
            };
        },

        computed: {
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),
            noSkip () {
                // Do not allow user to close modal if onboarding
                return this.onboarding;
            },

            buttonText () {
                return this.t(`slides.${this.slides[this.currentSlide]}.button_label`);
            },

            currentSlideName () {
                return this.slides[this.currentSlide];
            },
        },

        mounted () {
            if (!this.onboarding) this.slides = this.slides.slice(1);
            // Avoids FOUC (flash of unstyled content)
            this.$nextTick(() => this.loading = false);
            document.scrollingElement.classList.add('hide-scrollbars');
        },

        beforeUnmount () {
            document.scrollingElement.classList.remove('hide-scrollbars');
        },

        methods: {
            goToSlide (indexNum) {
                this.currentSlide = indexNum;
            },
            close () {
                if (!this.noSkip) this.closeModal();
            },
            next () {
                if (this.currentSlide === (this.slides.length - 1)) {
                    this.closeModal();
                }
                else {
                    this.$refs.howItWorksCarousel.next();
                }
            },
        },
    };
