import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { "data-e2e": "global-header" }
const _hoisted_4 = {
  id: "global-header",
  tabindex: "-1"
}
const _hoisted_5 = { role: "main" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PromoLinks = _resolveComponent("PromoLinks")
  const _component_MenuBar = _resolveComponent("MenuBar")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", null, [
      _createVNode(_component_MenuBar, null, {
        default: _withCtx(() => [
          _createVNode(_component_PromoLinks, { "aria-label": "nav_links" })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString($setup.t('global:title')), 1)
      ]),
      _createElementVNode("main", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default", { class: "content" })
      ])
    ])
  ]))
}