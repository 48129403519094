
    import { useI18next } from '@composables/i18next';
    import { mapActions, mapState } from 'vuex';
    import { submitPhoto } from '../file-upload';
    import { checkFeatureFlag } from '../../js/feature-flags';
    import BaseModal from './BaseModal';
    import openModal, { FileUploadCompleteModal, FileUploadCompleteModalHH } from '.';

    export default {
        name: 'FilePreviewModal',

        extends: BaseModal,

        props: {
            file: {
                type: File,
                required: true,
            },
        },

        setup () {
            const { t } = useI18next(['file_preview']);
            return { t };
        },

        data () {
            return {
                loadingImage: true,
                modalName: 'file-preview',
                submitting: false,
            };
        },

        computed: {
            ...mapState(['profile', 'ui']),
        },

        mounted () {
            this.readFile();
        },

        methods: {
            ...mapActions({
                updateUploadCount: 'receipt/updateUploadCount',
            }),

            checkFeatureFlag,

            closeModal (args) {
                this.$emit('close-modal', {
                    ...args,
                    clearValue: true,
                });
            },

            readFile () {
                const reader = new FileReader();
                reader.onload = (evt) => {
                    this.$refs.previewImage.src = evt.target.result;
                    this.loadingImage = false;
                };
                reader.readAsDataURL(this.file);
            },

            async submit () {
                this.submitting = true;

                try {
                    await submitPhoto(this.file);
                    await this.updateUploadCount();
                    this.closeModal();
                    if (this.ui.fileBrowser.lastOpenState.usePromoConfirm && this.profile.dunkin_fall24) {
                        openModal(FileUploadCompleteModalHH);
                    }
                    else {
                        openModal(FileUploadCompleteModal);
                    }
                }
                catch (err) {
                    // TODO: Are there any errors we need to handle from the back end or Veryfi?
                    console.error(err);
                }

                this.submitting = false;
            },
        },

        i18nOptions: { namespaces: ['file_preview'] },
    };
