
    import { useI18next } from '@composables/i18next';
    import { checkFeatureFlag } from '../feature-flags';
    import BaseModal from './BaseModal';

    export default {
        name: 'FileUploadCompleteModal',

        extends: BaseModal,

        setup () {
            const { t } = useI18next(['file_upload_complete']);
            return { t };
        },

        data () {
            return {
                modalName: 'receipt-upload-complete',
            };
        },

        methods: {
            checkFeatureFlag,
        },

        i18nOptions: { namespaces: ['file_upload_complete'] },
    };
