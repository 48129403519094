import axios from 'axios';
import handleErrorResponse from '@public/js/api-error';
import api from '../../api';

const state = {
    questions: null,
    result: {
        personality: null,
        percent: 0,
    },
};

const mutations = {
    setQuestions (state, questions) {
        state.questions = questions;
    },

    setResult (state, { data, stats }) {
        if (data.personalityCold > data.personalityHot) {
            state.result.personality =  'cold';
            state.result.percent = stats.data.coldPercent;
        }
        else {
            state.result.personality =  'hot';
            state.result.percent = stats.data.hotPercent;
        }
    },
};

const actions = {
    async getQuestions ({ commit, rootState }, surveyId) {
        try {
            const response = await axios.get(`${api.base}/surveys/${surveyId}/user/${rootState.profile.id}/state`);

            commit('setQuestions', response.data.questions);

            return response.data;
        }
        catch (err) {
            console.error(err);
        }
    },

    async submit ({ commit, dispatch }, { responses, surveyId }) {
        try {
            const response = await axios.post(`${api.base}/surveys/${surveyId}/responses`, { responses });

            // award points
            await dispatch('profile/recordEvent', 'july_survey', { root: true });

            // get updated points balance
            await dispatch('profile/getProfile', null, { root: true });

            const stats = await axios.get(`${api.base}/surveys/${surveyId}/stats`);

            commit('setResult', {
                data: response.data.personality,
                stats,
            });
        }
        catch (err) {
            console.error(err);
            handleErrorResponse(err);
            throw err;
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
