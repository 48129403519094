
    import { useI18next } from '@composables/i18next';
    import { checkFeatureFlag } from '../feature-flags';
    import openModal, { SweepstakeConfirmationModal } from '../modals';
    import BaseModal from './BaseModal';

    export default {
        name: 'RewardRedemptionCompleteModal',

        extends: BaseModal,

        props: {
            reward: {
                required: true,
                type: Object,
            },
        },

        setup () {
            const { t } = useI18next(['rewards_catalog']);
            return { t };
        },

        methods: {
            async sweepstakeConfirmationModal () {
                if (checkFeatureFlag('jetBluePromo')) {
                    const apiKey = 'dunkin_jetblue';
                    const sweepstakeConfirmationCopy = 'points_redeemed';
                    const reaction = await openModal(SweepstakeConfirmationModal, {
                        apiKey, sweepstakeConfirmationCopy,
                    });
                    this.$store.dispatch('hub/turnOnAction', reaction);
                }
                this.closeModal();
            },
        },
    };
