import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@public/img/white_dunkin.png'
import _imports_1 from '@public/img/receipt.svg'
import _imports_2 from '@public/img/card.png'
import _imports_3 from '@public/img/star.svg'


const _hoisted_1 = {
  id: "how-it-works-modal",
  class: "modal",
  role: "dialog",
  "aria-label": "How It Works"
}
const _hoisted_2 = { class: "slide-top" }
const _hoisted_3 = { class: "slide-headline" }
const _hoisted_4 = { class: "slide-bubble" }
const _hoisted_5 = {
  key: 0,
  alt: "",
  src: _imports_0,
  class: "at-home-logo",
  role: "presentation"
}
const _hoisted_6 = {
  key: 1,
  alt: "",
  src: _imports_1,
  class: "receipt",
  role: "presentation"
}
const _hoisted_7 = {
  key: 2,
  alt: "",
  src: _imports_2,
  class: "gift-card",
  role: "presentation"
}
const _hoisted_8 = {
  key: 3,
  alt: "",
  class: "star-1",
  src: _imports_3,
  role: "presentation"
}
const _hoisted_9 = {
  key: 4,
  alt: "",
  class: "star-2",
  src: _imports_3,
  role: "presentation"
}
const _hoisted_10 = {
  key: 5,
  alt: "",
  class: "star-3",
  src: _imports_3,
  role: "presentation"
}
const _hoisted_11 = {
  class: "slick-dots",
  style: {"display":"block"}
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["title"]
const _hoisted_15 = {
  key: 1,
  class: "disclaimer"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Slide = _resolveComponent("Slide")
  const _component_VueSlickCarousel = _resolveComponent("VueSlickCarousel")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => ($options.close()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!$data.loading)
        ? (_openBlock(), _createBlock(_component_VueSlickCarousel, _mergeProps({
            key: 0,
            ref: "howItWorksCarousel"
          }, $data.slickSettings, {
            modelValue: $data.currentSlide,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.currentSlide) = $event))
          }), {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.slides, (slide) => {
                return (_openBlock(), _createBlock(_component_Slide, {
                  key: slide,
                  class: _normalizeClass(`slide-${slide}`)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("h2", null, _toDisplayString($setup.t(`slides.${slide}.headline_copy`)), 1)
                      ]),
                      _createElementVNode("div", _hoisted_4, [
                        (slide === 'welcome')
                          ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                          : _createCommentVNode("", true),
                        (slide === 'earn')
                          ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                          : _createCommentVNode("", true),
                        (slide === 'redeem')
                          ? (_openBlock(), _createElementBlock("img", _hoisted_7))
                          : _createCommentVNode("", true),
                        (slide === 'extra')
                          ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                          : _createCommentVNode("", true),
                        (slide === 'extra')
                          ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                          : _createCommentVNode("", true),
                        (slide === 'extra')
                          ? (_openBlock(), _createElementBlock("img", _hoisted_10))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("p", null, _toDisplayString($setup.t(`slides.${slide}.body_copy`)), 1)
                  ]),
                  _: 2
                }, 1032, ["class"]))
              }), 128))
            ]),
            _: 1
          }, 16, ["modelValue"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createElementVNode("ul", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.slides, (slide, slideIndex) => {
            return (_openBlock(), _createElementBlock("li", {
              key: slideIndex,
              class: _normalizeClass({ 'slick-active': $data.currentSlide == slideIndex }),
              onClick: $event => ($options.goToSlide(slideIndex))
            }, [
              _createElementVNode("button", {
                onClick: $event => ($options.goToSlide(slideIndex))
              }, _toDisplayString(slideIndex + 1), 9, _hoisted_13)
            ], 10, _hoisted_12))
          }), 128))
        ]),
        _createElementVNode("button", {
          class: "next-btn",
          title: $options.buttonText,
          onClick: _cache[1] || (_cache[1] = _withModifiers($event => ($options.next()), ["prevent"]))
        }, _toDisplayString($options.buttonText), 9, _hoisted_14)
      ]),
      ($options.currentSlideName === 'redeem')
        ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString($setup.t('slides.redeem.disclaimer')), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}