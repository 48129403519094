import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "coupon-modal",
  class: "modal",
  role: "dialog",
  "aria-labelledby": "modal-title"
}
const _hoisted_2 = { id: "modal-title" }
const _hoisted_3 = { class: "fine-print" }
const _hoisted_4 = { class: "expiration" }
const _hoisted_5 = { class: "exclusions" }
const _hoisted_6 = { class: "disclaimer" }
const _hoisted_7 = { class: "terms" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString($setup.t('redemption_confirmed.headline_copy')), 1),
      _createElementVNode("p", null, _toDisplayString($setup.t('redemption_confirmed.body_copy')), 1),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.closeModal()))
      }, _toDisplayString($setup.t('buttons:continue')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_4, _toDisplayString($setup.t(`${$props.couponId}.expiration`)), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString($setup.t(`${$props.couponId}.exclusions`)), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_6, _toDisplayString($setup.t('generic.disclaimer')), 1),
          _createElementVNode("p", _hoisted_7, [
            _createElementVNode("a", {
              href: "",
              onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.openTermsModal && $options.openTermsModal(...args)), ["prevent"]))
            }, _toDisplayString($setup.t('generic.terms')) + ". ", 1)
          ])
        ])
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-btn",
        title: "Close modal",
        onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("i", {
          "aria-hidden": "true",
          class: "fa-solid fa-xmark"
        }, null, -1)
      ]))
    ])
  ]))
}