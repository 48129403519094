import axios from 'axios';
import { reactive } from 'vue';
import handleErrorResponse from '@public/js/api-error';
import api from '../../api';

const getDefaultState = () => reactive({
    uploadCount: 0,
});

const state = getDefaultState();

const mutations = {
    updateReceiptState (state, data) {
        if (!Array.isArray(data)) {
            data = [data];
        }

        for (const values of data) {
            for (const key in values) {
                state[key] = values[key];
            }
        }
    },
    updateUploadCount (state, value) {
        state.uploadCount = value;
    },
};

const actions = {
    async getUploadCount ({ dispatch, rootState, commit }) {
        const profileId = rootState.profile.id;

        if (!profileId) return;

        try {
            return dispatch('makeCall', {
                type: 'get',
                endpoint: `receipts/${profileId}/upload-count`,
            });
        }
        catch (err) {
            console.log('suppressed getUploadCount error', err);
            handleErrorResponse(err);
            return;
        }
    },

    async updateUploadCount ({ state, dispatch, commit }) {
        commit('updateUploadCount', 1);
    },

    async makeCall ({ state, dispatch, commit }, {
        type = 'post',
        endpoint,
        data = {},
    }) {

        try {
            const response = await axios[type](`${api.base}/${endpoint}`, data);
            commit('updateReceiptState', response.data);

            return response;
        }
        catch (err) {
            console.error(`error calling ${endpoint}`, err);
            handleErrorResponse(err);
            throw err;
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
