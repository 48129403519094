import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/header.svg'


const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { "data-e2e": "global-header" }
const _hoisted_3 = {
  id: "global-header",
  tabindex: "-1"
}
const _hoisted_4 = {
  ref: "headerLockup",
  class: "headerimg",
  src: _imports_0,
  alt: "Dunkin' At Home Extras"
}
const _hoisted_5 = { role: "main" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PromoLinks = _resolveComponent("PromoLinks")
  const _component_MenuBar = _resolveComponent("MenuBar")
  const _component_TheFooter = _resolveComponent("TheFooter")

  return (_openBlock(), _createElementBlock("div", {
    id: "app",
    ref: "app",
    class: _normalizeClass({ 'mounted': $data.mounted })
  }, [
    _createElementVNode("nav", null, [
      _createVNode(_component_MenuBar, null, {
        default: _withCtx(() => [
          _createVNode(_component_PromoLinks, { "aria-label": "nav_links" })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("header", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString($setup.t('global:title')), 1),
        _createElementVNode("img", _hoisted_4, null, 512)
      ]),
      _createElementVNode("main", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default", { class: "content" })
      ]),
      _createVNode(_component_TheFooter, {
        ref: "footer",
        hidelinks: "footer"
      }, null, 512)
    ])
  ], 2))
}