
    import { useI18next } from '@composables/i18next';
    import axios from 'axios';
    import api from '@public/js/api';
    import { BaseSelect } from '.';

    export default {
        components: {
            BaseSelect,
        },

        setup () {
            const { t } = useI18next(['global']);
            return { t };
        },

        data () {
            return {
                states: [],
            };
        },

        computed: {
            eligibleStateOptions () {
                return this.states.map((value) => ({
                    value,
                    label: this.t(`global:state.${value}.abbrev`) || value,
                }));
            },
        },

        async mounted () {
            await this.loadEligibleStates();
        },

        methods: {
            async loadEligibleStates () {
                try {
                    const { data: { states } } = await axios.get(`${api.base}/eligible-states`);
                    this.states = states;
                    return;
                }
                catch (err) {
                    console.error(
                        'error making eligible states call',
                        err.message,
                        err,
                    );

                    return;
                }
            },
        },

        i18nOptions: { namespaces: ['global'] },
    };
