
    import { usePage } from '@composables/page';
    import { useI18next } from '@composables/i18next';

    export default {
        name: 'ErrorPage',

        props: {
            type: {
                type: String,
                default: 'default',
            },
        },

        setup () {
            usePage('error');
            const { t } = useI18next('error');
            return { t };
        },

        data () {
            return {
                pageName: 'error',
            };
        },

        computed: {
            isWhiteListedCopy () {
                return this.type === 'unauthorized';
            },
            finalType () {
                return this.isWhiteListedCopy ? this.type : 'default';
            },
        },
    };
