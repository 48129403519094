
    import { useI18next } from '@composables/i18next';

    export default {
        props: {
            id: {
                required: true,
                type: [String, Number],
            },
        },

        setup () {
            const { t } = useI18next(['rewards_catalog']);
            return { t };
        },
    };
