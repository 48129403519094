
    import { useI18next } from '@composables/i18next';
    import { mapState } from 'vuex';
    import MenuBar from '@components/MenuBar.vue';
    import PromoLinks from '@components/PromoLinks.vue';
    import TheFooter from '@components/TheFooter.vue';
    import { checkFeatureFlag } from '../feature-flags';

    export default {
        components: {
            TheFooter,
            MenuBar,
            PromoLinks,
        },
        setup () {
            const { t } = useI18next(['global', 'landing']);
            return { t };
        },
        computed: {
            ...mapState(['app']),
        },
        methods: {
            checkFeatureFlag,
            buildDynamicHref (target) {
                const authPage = target === 'register' ? 'signup' : 'login';
                return `oauth/authenticate?target=${authPage}`;
            },
        },
    };
