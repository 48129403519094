import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  class: "modal photo-upload-tips",
  role: "dialog",
  "aria-label": "Photo upload tips"
}
const _hoisted_2 = { class: "uploads_tip_container" }
const _hoisted_3 = { id: "uploads_tip_carousel" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "need_more_tips" }
const _hoisted_8 = ["title"]
const _hoisted_9 = { class: "long-receipt-container" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 1,
  class: "step-img-wrapper"
}
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  class: "slick-dots",
  style: {"display":"block"}
}
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Slide = _resolveComponent("Slide")
  const _component_VueSlickCarousel = _resolveComponent("VueSlickCarousel")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay photo-upload-tips-modal",
    onClick: _cache[5] || (_cache[5] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (!$data.loading)
            ? (_openBlock(), _createBlock(_component_VueSlickCarousel, _mergeProps({
                key: 0,
                ref: "carousel"
              }, $data.slickSettings, {
                modelValue: $data.currentSlideIndex,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.currentSlideIndex) = $event))
              }), {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.slides, (slide) => {
                    return (_openBlock(), _createBlock(_component_Slide, { key: slide }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", {
                          innerHTML: $setup.t(`slides.slide_${slide}.body_copy`)
                        }, null, 8, _hoisted_4),
                        _createElementVNode("h2", {
                          innerHTML: $setup.t(`slides.slide_${slide}.headline_copy`)
                        }, null, 8, _hoisted_5),
                        (slide === 4 && $data.currentSlideIndex === 3)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createElementVNode("div", null, [
                                _createElementVNode("img", {
                                  src: `../../public/img/upload_tips/step${slide}.gif`,
                                  class: _normalizeClass([`step${slide}_img`, "step_img"]),
                                  alt: "Grocery retailer only (no Dunkin' restaurant receipts), purchase date and time, eligible product, purchase price, receipt total"
                                }, null, 10, _hoisted_6)
                              ]),
                              _createElementVNode("div", _hoisted_7, [
                                _cache[6] || (_cache[6] = _createElementVNode("i", {
                                  "aria-hidden": "true",
                                  class: "fa-solid fa-lightbulb-on need_more_tips_icon"
                                }, null, -1)),
                                _createElementVNode("a", {
                                  href: "#/receipt_upload_tips",
                                  title: `${$setup.t('receipt_upload_tips:headline_copy')} ${$setup.t('links:new_window')}`,
                                  onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => ($options.openPopup && $options.openPopup(...args)), ["prevent"]))
                                }, _toDisplayString($setup.t(`slides.slide_${slide}.need_more_tips`)), 9, _hoisted_8)
                              ]),
                              _createElementVNode("div", _hoisted_9, [
                                _createElementVNode("h3", {
                                  class: "long-receipt-headline",
                                  innerHTML: $setup.t(`slides.slide_${slide}.long_receipt_headline`)
                                }, null, 8, _hoisted_10),
                                _createElementVNode("p", {
                                  class: "long-receipt-body",
                                  innerHTML: $setup.t(`slides.slide_${slide}.long_receipt_body_copy`)
                                }, null, 8, _hoisted_11)
                              ])
                            ], 64))
                          : _createCommentVNode("", true),
                        (slide !== 4)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              _createElementVNode("img", {
                                alt: "",
                                src: `../../public/img/upload_tips/step${slide}.gif`,
                                class: _normalizeClass([`step${slide}_img`, "step_img"]),
                                role: "presentation"
                              }, null, 10, _hoisted_13)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 16, ["modelValue"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("ul", _hoisted_14, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.slides, (slide, slideIndex) => {
            return (_openBlock(), _createElementBlock("li", {
              key: slideIndex,
              class: _normalizeClass({ 'slick-active': $data.currentSlideIndex == slideIndex }),
              onClick: $event => ($options.goToSlide(slideIndex))
            }, [
              _createElementVNode("button", {
                onClick: $event => ($options.goToSlide(slideIndex))
              }, _toDisplayString(slideIndex + 1), 9, _hoisted_16)
            ], 10, _hoisted_15))
          }), 128))
        ]),
        _createElementVNode("a", {
          href: "#",
          class: "btn_close button",
          onClick: _cache[2] || (_cache[2] = _withModifiers((...args) => ($options.nextSlide && $options.nextSlide(...args)), ["prevent"]))
        }, _toDisplayString($options.btnCopy), 1),
        ($data.currentSlideIndex === 3)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: "disclaimer",
              innerHTML: $setup.t(`slides.disclaimer`)
            }, null, 8, _hoisted_17))
          : _createCommentVNode("", true),
        ($data.currentSlideIndex !== 3)
          ? (_openBlock(), _createElementBlock("p", _hoisted_18, [
              _createElementVNode("a", {
                href: "",
                onClick: _cache[3] || (_cache[3] = _withModifiers($event => ($options.skipTips()), ["prevent"]))
              }, _toDisplayString($setup.t('skip')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-btn",
        title: "Close modal",
        onClick: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[7] || (_cache[7] = [
        _createElementVNode("i", {
          "aria-hidden": "true",
          class: "fa-solid fa-xmark"
        }, null, -1)
      ]))
    ])
  ]))
}