import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "modal-overlay" }
const _hoisted_2 = {
  key: 0,
  class: "points-banner"
}
const _hoisted_3 = { id: "modal-title" }
const _hoisted_4 = {
  id: "avatar-selection-carousel",
  ref: "carouselContainer"
}
const _hoisted_5 = { class: "wrapper-wrapper" }
const _hoisted_6 = { class: "avatar-wrapper" }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = {
  key: 1,
  class: "skip"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Slide = _resolveComponent("Slide")
  const _component_VueSlickCarousel = _resolveComponent("VueSlickCarousel")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "avatar-selection-modal",
      ref: "avatarSelection",
      class: _normalizeClass(["modal orange-bkgd", { 'first-selection': !_ctx.profile.avatar }]),
      role: "dialog",
      "aria-labelledby": "modal-title"
    }, [
      (!_ctx.profile.avatar)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.t('avatar_selection.points')), 1))
        : _createCommentVNode("", true),
      _createElementVNode("h2", _hoisted_3, _toDisplayString($setup.t('avatar_selection.headline_copy')), 1),
      _createElementVNode("div", _hoisted_4, [
        (!$data.loading)
          ? (_openBlock(), _createBlock(_component_VueSlickCarousel, _mergeProps({ key: 0 }, $data.slickSettings, {
              ref: "carousel",
              modelValue: $data.selectedAvatarPosition,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.selectedAvatarPosition) = $event))
            }), {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.avatars, (avatar) => {
                  return (_openBlock(), _createBlock(_component_Slide, {
                    key: avatar,
                    class: "avatar-slide"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("img", {
                            src: `../../public/img/avatars/${avatar}.svg`,
                            alt: `${avatar} avatar`,
                            class: _normalizeClass(["avatar", avatar])
                          }, null, 10, _hoisted_7)
                        ])
                      ])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 16, ["modelValue"]))
          : _createCommentVNode("", true)
      ], 512),
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = _withModifiers($event => ($options.submitAvatarSelection()), ["prevent"]))
        }, _toDisplayString($setup.t('buttons:select_continue')), 1)
      ]),
      ($props.onboarding)
        ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
            _createElementVNode("a", {
              href: "",
              onClick: _cache[2] || (_cache[2] = _withModifiers($event => ($options.skip()), ["prevent"]))
            }, _toDisplayString($setup.t('skip')), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        href: "#",
        class: "close-btn",
        title: "Close modal",
        onClick: _cache[3] || (_cache[3] = _withModifiers($event => ($options.skip()), ["prevent"]))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("i", {
          "aria-hidden": "true",
          class: "fa-solid fa-xmark"
        }, null, -1)
      ]))
    ], 2)
  ]))
}