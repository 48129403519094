import { reactive, computed } from 'vue';
import createAnalytics, { VuePlugin } from '@roc/web-analytics';
import store from './store';

const reactiveData = reactive({
    deployPath: computed(() => store.state.app.deployPath),
    affiliateId: computed(() => store.state.app.affiliateId),
    pageName: computed(() => store.state.ui.pageName),
    isLoggedIn: computed(() => store.getters['profile/loggedIn']),
});

// Create instance with access to reactive data
const
    webAnalytics = createAnalytics({
        packageName: process.env.VUE_APP_PACKAGE_NAME,
        reactiveData,
    }),
    { page, track } = webAnalytics;

export default webAnalytics;
export { page, track, VuePlugin };
