
import axios from 'axios';
import { reactive } from 'vue';

import handleErrorResponse from '@public/js/api-error';
import api from '../../api';

const getDefaultState = () => reactive({
    transactions: [],
});

const state = getDefaultState();

const getters = {};

const mutations = {
    updateTransactionHistory (state, data) {
        state.transactions = [...data];
    },
};

const actions = {
    async getTransactionHistory ({ commit }) {
        try {
            const { data: { history = [] } } = await axios.get(`${api.base}/loyalty/user/me/transaction-history`);

            commit('updateTransactionHistory', history);
        }
        catch (err) {
            console.error('failed to load transaction history', err);
            handleErrorResponse(err);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
